export const environment = {
  production: false,
  config: {
    API_BASE_URL: 'https://api.projectfitnesstv.com',
	AWS_S3_PATH: 'https://pftv-uploads.s3.us-east-2.amazonaws.com',
	rooms: '/user/createroom',
	allUserEvents: '/user/getrooms',
	getroombyid: '/user/getroom',
	FIREBASE: {
		apiKey: 'AIzaSyCZE3xwbqhUAkwetejL68JEz4KfbtfWXhA',
		authDomain: 'project-fitness-tv-tapakila.firebaseapp.com',
		databaseURL: 'https://project-fitness-tv-tapakila.firebaseio.com',
		projectId: 'project-fitness-tv-tapakila',
		storageBucket: 'project-fitness-tv-tapakila.appspot.com',
		messagingSenderId: '258923416075',
		appId: '1:258923416075:web:de01133cb1fb1eb36cd50e',
		measurementId: 'G-8S0VCR8PW3'
	  },
	LIVE: {
		HLS_BASE_URL: 'https://live.projectfitnesstv.com/hls',
		ENCODER_BASE_URL: 'wss://encoder.projectfitnesstv.com'
		// HLS_BASE_URL: 'https://live.projectfitnesstv.com/hls',
		// ENCODER_BASE_URL: 'wss://encoder.projectfitnesstv.com'
	}	
  },
  
};